// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const portalEnvironment = {
  production: '#{production}',
  apiUrl:  'https://azfun-azuf-euw-qa-nps-gitc.azurewebsites.net',
  authMember: {
    clientId: '287d8b2b-2b0b-480d-b9d0-d36e7b1d9bf1',
    authority: 'https://login.microsoftonline.com/common',
    scopes: ['openid']
  },
  authO365: {
    clientId: '287d8b2b-2b0b-480d-b9d0-d36e7b1d9bf1',
    authority: 'https://login.microsoftonline.com/common',
    scopes: ['openid']
  },
  appInsightsKey: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
